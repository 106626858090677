import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

interface StateProps {
  LocationState: LocationData
}

interface LocationData {
  roleLocation: string
  link: string
}

const business_development_representative: React.FC<StateProps> = () => {
  const [locationData, setLocationData] = useState<LocationData>({
    roleLocation: 'London, UK',
    link: 'https://apply.workable.com/lensesio/j/4081DD08F9/',
  })

  const { roleLocation, link } = locationData

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var location = url.searchParams.get('location')
    if (location == 'us') {
      setLocationData({
        roleLocation: 'New York, NY',
        link: 'https://apply.workable.com/lensesio/j/4081DD08F9/',
      })
    }
  }, [])

  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Business Development Representative in London & New York. Apply to join our team!',
    },
    keywords: {
      keywords:
        'Business Development Representative, London,NY,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/4ROT9OQZPCyfESOc4NKJHG/eb794544afa652c13facdb08c5394448/business-development-representative.jpg',
      },
    },
    title: {
      title: 'Business Development Representative London',
    },
  }
  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link
          rel="canonical"
          href="https://lenses.io/careers/business-development-representative/"
        />
      </Helmet>
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Business Development Representative</h1>
          <p className="meta">{roleLocation}</p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>

            <ul className="pl-4 ">
              <li>
                Research accounts and prospects to develop compelling and
                tailored value propositions
              </li>
              <li>
                Prospect, educate, qualify, and develop target accounts from
                lead-generation activities, such as outbound cold-calling,
                emailing, and direct mailers into targeted accounts
              </li>
              <li>
                Engage with prospects via phone, email, and social selling tools
              </li>
              <li>Distribute qualified new opportunities to sales</li>
              <li>
                Collaborate with Account Executives to optimize outbound efforts
              </li>
              <li>
                Work closely with marketing to capitalize on warm engagement
                from demand generationand marketing initiatives
              </li>
              <li>
                Maintain notes and activity tracking of accounts and engagement
                in SFDC
              </li>
              <li>
                Implement strategy for acquiring new business and traction in
                your assigned territory
              </li>
              <li>
                Attain specific metrics to grow the business and help the sales
                team achieve their goals
              </li>
              <li>Develop in-depth knowledge about the real-time data space</li>
              <li>
                Build the foundations of the sales development program,
                including communications and procedure
              </li>
            </ul>

            <h5 className="pt-3">What you will bring:</h5>

            <ul className="pl-4 ">
              <li>
                Hands on experience as a SDR/BDR and ability to work in fast
                paced, constantly evolving environment
              </li>
              <li>Excellent verbal and written communication skills</li>
              <li>Exceptional ability to organize work and tasks</li>
              <li>
                A strong curiosity to understand the unique challenges that
                companies in different industries face
              </li>
              <li>Desire to build a career in tech sales</li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default business_development_representative
